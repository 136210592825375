<template>
    <div class="form">
        <Nav navType="backnav" textCon="商家入驻申请"></Nav>
        <div class="mt3"></div>
        <div class="form-item">
            <div class="label">商户性质</div>
            <div class="select">
                <checkbox name="a" v-model="checked1" @change="checkChange1">个人</checkbox>
            </div>
            <div class="select">
                <checkbox name="a" v-model="checked0" @change="checkChange0">企业</checkbox>
            </div>
        </div>
        <div class="form-item">
            <div class="label">商户名称</div>
            <div class="input">
                <field v-model="formData.merch_name" placeholder=""  />
            </div>
        </div>
        <div class="form-item">
            <div class="label">商户账号</div>
            <div class="input">
                <field v-model="formData.user" placeholder="用户登录商家端后台，请妥善保存" />
            </div>
        </div>
        <div class="form-item">
            <div class="label">商户密码</div>
            <div class="input">
                <field v-model="formData.password" placeholder="用户登录商家端后台，请妥善保存"  />
            </div>
        </div>
        <div class="form-item">
            <div class="label">主营项目</div>
            <div class="input">
                <field v-model="formData.run_type" placeholder="如鞋帽、化妆品、手机数码等" maxlength="15" @input="computedlength(1)"/>
            </div>
            <div class="text-length"><span>{{text_length1}}</span>/15</div>
        </div>
        <div class="form-item">
            <div class="label">入驻板块</div>
            <div class="select">
                <checkbox name="a" v-model="checked3" @change="checkChange3">商城</checkbox>
            </div>
            <div class="select">
                <checkbox name="a" v-model="checked2" @change="checkChange2">团购</checkbox>
            </div>
        </div>
        <div class="form-item">
            <div class="label">入驻行业</div>
            <div class="input" style="padding-left:15px">
                <span v-if="industryName">{{industryName}}</span>
                <span @click="showIndustry = true" class="text_button">{{buttonText}}</span>
            </div>
        </div>
        <div class="form-item">
            <div class="label">简单介绍</div>
            <div class="input">
                <field type="textarea" v-model="formData.brief" placeholder="请简单的介绍下商户,自动换行显示全部内容，100个字内，超过不再显示显示" maxlength="100" rows="3" @input="computedlength(2)"/>
            </div>
            <div class="text-length"><span>{{text_length2}}</span>/100</div>
        </div>
        <div class="mt3"></div>
        <div class="form-item">
            <div class="label">联系人</div>
            <div class="input">
                <field v-model="formData.contact_name" maxlength="15"/>
            </div>
        </div>
        <div class="form-item">
            <div class="label">联系电话</div>
            <div class="input">
                <field v-model="formData.contact_mobile" maxlength="15"/>
            </div>
        </div>
        <div class="form-item">
            <div class="label">有无店铺</div>
            <div class="select">
                <checkbox name="a" v-model="checked4" @change="checkChange4">有实体店铺</checkbox>
            </div>
            <div class="select">
                <checkbox name="a" v-model="checked5" @change="checkChange5">无实体店铺</checkbox>
            </div>
        </div>
        <div class="form-item" v-if="checked4">
            <div class="label">详细地址</div>
            <div class="input">
                <field type="textarea" v-model="formData.address" rows="3"/>
            </div>
        </div>
        <div class="form-item" v-if="checked4">
            <div class="label">地理位置</div>
            <div class="input" @click="gotoSelectMap">
                <span>经度：{{point.lng}}</span>
                <span>纬度：{{point.lat}}</span>
            </div>
        </div>
        <div class="form-item">
            <div class="read">
                <checkbox name="a" v-model="checked6">我已阅读并同意<span>《乐享靖江app商家入驻申请协议》</span></checkbox>
            </div>        
        </div>
        <div class="bottom">
            <div class="button" @click="submit()">立即申请入驻</div>
        </div>
        <div class="success" v-if="showsuccess">
            <h1>申请成功</h1>
            <p>您的商家入驻申请已成功受理</p>
            <p>我们会在1-3个工作日内与您取得联系</p>
            <div class="close" @click="close()">我知道了</div>
        </div>
        <Overlay :show="showIndustry">
            <div class="overlay_content">
                <div class="industry_box">
                    <div class="box_head">请选择入驻行业
                        <span @click="showIndustry =false">关闭</span>
                    </div>
                    <div class="industry_list">
                        <div class="industry_item" v-if="showLastPage" @click="lastPage()">返回上一级</div>
                        <div class="industry_item" v-for="(item,index) in showlist" :key="item.id" @click="selectItem(index)">
                            <div class="name">{{item.name}}</div>
                            <div class="arrow" v-if="item.children.length">
                                <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
                            </div>
                        </div>
                    </div>
                    <div class="industry_list" v-if="industryList.length == 0">
                        <div class="industry_empty">空</div>
                    </div>
                </div>
            </div>
        </Overlay>
    </div>
</template>
<script>
import { Checkbox,Field,Overlay  } from "vant";
import Nav from "@components/public/searchTop";
import Apply from "@/api/apply"
export default {
    data(){
        return{
            formData:{},
            checked0:false, //企业
            checked1:true,  //个人
            checked2:false, //团购
            checked3:true,  //商城
            checked4:true,  //有实体店铺
            checked5:false, //无实体店铺
            checked6:false, //协议
            text_length1:0, //主营项目字数统计
            text_length2:0, //简介字数统计
            point:{lng:"",lat:""},  //定位
            showsuccess:false,  //提交成功提示窗
            industryName:"",    //选择的行业名称
            buttonText:"点击选择",  //按钮文字
            showIndustry: false, //显示行业列表
            industryList:[], //行业列表
            showlist:[],    //展示的行业列表
            showLastPage: false, //是否有上一页
            history:[], //选择行业历史栈
        }
    },
    mounted(){
        this.getState();
        this.getKeepForm();
        this.getIndustryList();
        // this.test();
    },
    methods:{
        //判断用户是否已申请
        async getState() {
            var data = {}
            try {
                const res = await Apply.GetInfo(data);
                if (res.msg == '后台审核中') {
                    this.showsuccess = true;
                } else{
                    console.log(res);
                }
            } catch(err) {
                console.log(err);
            }
        },
        getKeepForm(){
            if(sessionStorage.getItem('keepForm')){
                var keepForm = Object.assign({},JSON.parse(sessionStorage.getItem('keepForm')))
                console.log(keepForm)
                this.formData = Object.assign({},keepForm.formData)
                this.checked0 = keepForm.checked0
                this.checked1 = keepForm.checked1
                this.checked2 = keepForm.checked2
                this.checked3 = keepForm.checked3
                this.checked4 = keepForm.checked4
                this.checked5 = keepForm.checked5
                this.checked6 = keepForm.checked6
                sessionStorage.removeItem('keepForm')
                this.point = JSON.parse(sessionStorage.getItem('MapPoint'))
            }
        },
        //测试选择行业
        test(){
            this.industryList = [
                {
                    "id":4,
                    "name":"电商4",
                    "children":[
                        {
                            "id":5,
                            "name":"电商5二层",
                            "children":[
                                {
                                    "id":6,
                                    "name":"电商6三层",
                                    "children":[
                                        {
                                            "id":11,
                                            "name":"电商11四层",
                                            "children":[

                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id":7,
                            "name":"电商7",
                            "children":[
                                {
                                    "id":8,
                                    "name":"电商8",
                                    "children":[

                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "id":3,
                    "name":"电商3",      
                    "children":[
                        {
                            "id":9,
                            "name":"电商9",      
                            "children":[

                            ]
                        },
                    ]
                },
                {
                    "id":2,
                    "name":"电商1",
                    "children":[

                    ]
                }
            ];
            this.industryList = [];
            this.showlist = this.industryList;
        },
        //获取行业列表
        async getIndustryList() {
            try {
                const res = await Apply.Industry();
                if (res.code == 200) {
                    this.industryList = res.data;
                    this.showlist = this.industryList;
                } else{
                    console.log(res);
                }
            } catch(err) {
                console.log(err);
            }
        },
        lastPage(){
            this.history.pop();
            console.log(this.history);
            if(this.history.length == 0){
                this.showlist = this.industryList;
                this.showLastPage = false;
            }else{
                var list = this.industryList;
                this.history.forEach(item=>{
                    list = list[item].children;
                })
                this.showlist = list;
            }
        },
        //选择行业
        selectItem(index){
            console.log(index);
            console.log(this.showlist[index]);
            if(this.showlist[index].children.length){
                this.history.push(index);
                this.showlist = this.showlist[index].children;
                this.showLastPage = true;
            }else{
                this.industryName = this.showlist[index].name;
                this.formData.industry_id = this.showlist[index].id;
                this.buttonText = '重新选择';
                this.showIndustry = false;
                this.showlist = this.industryList;
            }
        },
        //统计字数
        computedlength(i){
            if(i == 1){
                this.text_length1 = this.formData.run_type.split('').length
            }
            if(i == 2){
                this.text_length2 = this.formData.brief.split('').length
            }
        },
        checkChange0(value){
            this.checked1 = !value;
        },
        checkChange1(value){
            this.checked0 = !value;
        },
        checkChange2(value){
            this.checked3 = !value;
        },
        checkChange3(value){
            this.checked2 = !value;
        },
        checkChange4(value){
            this.checked5 = !value;
        },
        checkChange5(value){
            this.checked4 = !value;
        },
        submit(){
            var data = Object.assign({},this.formData)
            data.settle_type = this.checkChange2? 0:1;
            data.is_shop = this.checkChange4? 0:1;
            data.lng = this.point.lng;
            data.lat = this.point.lat;
            console.log(data)
            if(!data.merch_name){
                this.$toast('请输入商户名称')
            }else if(!data.user){
                this.$toast('请输入商户账号')
            }else if(!data.password){
                this.$toast('请输入商户密码')
            }else if(!data.run_type){
                this.$toast('请输入主营项目')
            }else if(!data.industry_id){
                this.$toast('请选择入驻行业')
            }else if(!data.contact_name){
                this.$toast('请输入联系人')
            }else if(!data.contact_mobile){
                this.$toast('请输入联系电话')
            }else if(!this.checked6){
                this.$toast('请阅读并同意入驻协议')
            }
            this.send(data)
            // this.showsuccess = true;
        },
        async send(data) {
            try {
                const res = await Apply.ShopApply(data);
                if (res.code == 200) {
                    console.log(res.msg)
                    this.showsuccess = true;
                } else{
                    console.log(res);
                }
            } catch(err) {
                console.log(err);
            }
        },
        gotoSelectMap(){
            var form = {}
            form.formData = Object.assign({},this.formData)
            form.checked0 = this.checked0
            form.checked1 = this.checked1
            form.checked2 = this.checked2
            form.checked3 = this.checked3
            form.checked4 = this.checked4
            form.checked5 = this.checked5
            form.checked6 = this.checked6
            sessionStorage.setItem('keepForm',JSON.stringify(form))
            console.log("gotomap")
            this.$router.push('/selectMap')
        },
        close(){
            this.showsuccess = false;
            this.$router.go(-1);
        },
    },
    components:{
        Checkbox,
        Field,
        Nav,
        Overlay
    },
}
</script>
<style lang="scss" scoped>
.form{
    width:100%;
    height: 100%;
    background: #fff;
    padding-top: 45px;
    overflow: auto;
    padding-bottom: 80px;
}
.mt3{
    width:100%;
    height: 7.5px;
    background-color: #f4f4f4;
}
.form-item{
    position: relative;
    width:100%;
    display: flex;
    border-top: 1px solid #f4f4f4;
    padding-left: 23px;
    .label{
        width:84px;
        font-size: 14px;
        line-height: 45px;
        color:#333;
    }
    .select{
        flex:1;
        padding-top:16px;
        margin-left: 16px;
    }
    .input{
        flex:1;
        font-size: 13px;
        span{
            line-height: 45px;
            margin-right: 15px;
        }
        .text_button{
            padding:6px 10px;
            border:1px solid #eee;
            border-radius: 5px;
        }
    }
    .text-length{
        position: absolute;
        width:auto;
        height: 8px;
        font-size: 8px;
        right: 12px;
        bottom:8px;
        color: #8c8c8c;
        span{
            color:#ff8000;
        }
    }
    .read{
        font-size: 14px;
        margin-top:7.5px;
        span{
            color:#ff8000;
        }
    }
}
.bottom{
    width:100%;
    height: 48px;
    position: fixed;
    background: #fff;
    bottom:0;
    left: 0;
    .button{
        width:92%;
        height: 36px;
        margin:6px auto;
        border-radius: 18px;
        color:#fff;
        text-align: center;
        line-height: 36px;
        font-size: 15px;
        background: linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
    }
}
.overlay_content{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .industry_box{
        width: 70%;
        background-color: #fff;
        font-size: 14px;
        .box_head{
            text-align: center;
            height: 45px;
            line-height: 45px;
            border-bottom: 1px solid #eee;
            position: relative;
            span{
                position: absolute;
                right: 0;
                height: 45px;
                padding-right: 12px;
                color: #FF8000;
            }
        }
        .industry_list{
            max-height: 55vw;
            overflow: auto;
            .industry_item{
                border-bottom: 1px solid #eee;
                height: 45px;
                line-height: 45px;
                display: flex;
                padding: 0 12px;
                .name{
                    flex:1;
                }
                .arrow{
                    width:7px;
                    margin-left: 3px;
                }
            }
            .industry_empty{
                height: 90px;
                line-height: 90px;
                text-align: center;
                font-size: 14px;
                color: #8C8C8C;
            }
        }
    }
}
.success{
    position: fixed;
    width:100%;
    top:7.5px;
    bottom: 0;
    background: #fff;
    text-align: center;
    padding-top:93px;
    h1{
        font-size: 19px;
        color: #000;
        margin-top:23px;
        margin-bottom: 16px;
        font-weight: 500
    }
    p{
        color:#8C8C8C;
        font-size: 13px;
        line-height: 20px;
    }
    .close{
        width:26%;
        height: 26px;
        border-radius: 13px;
        border:1px solid #ff8000;
        color:#FF8000;
        margin:16px auto;
        font-size: 17px;
        line-height: 26px;
    }
}
</style>


