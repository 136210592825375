import { post, App_ID } from "./publicHeader";
// import { post } from "@/lin/plugins/applyAxios"
class Apply {
	//商家入驻申请
	async ShopApply(data) {
		console.log(data);
		const res = await post("/merch/user.merch/apply", {
			app_id: App_ID,
			address: data.address,
			brief: data.brief,
			contact_mobile: data.contact_mobile,
			contact_name: data.contact_name,
			is_shop: data.is_shop,
			lat: data.lat,
			lng: data.lng,
			merch_name: data.merch_name,
			password: data.password,
			run_type: data.run_type,
			settle_type: data.settle_type,
			user: data.user
		});
		return res;
	}

	//入驻行业列表
	async Industry(data) {
		console.log(data);
		const res = await post("/merch/user.merch/industry", {
			app_id: App_ID
		});
		return res;
	}

	//获取入驻状态
	async GetInfo() {
		const res = await post("/merch/user.merch/info", {
			app_id: App_ID
		});
		return res;
	}
}

export default new Apply();
